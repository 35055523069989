import React from "react"
import { Card, Col, Row } from "reactstrap"
import Utils from "../Common/Utils"

const CustomerRecurringPayments = ({ recurringPayments }) => {
  const activePayments = recurringPayments?.filter(
    payment => payment.canceled === false,
  )
  const canceledPayments = recurringPayments?.filter(
    payment => payment.canceled === true,
  )

  return (
    <Card className="card-area" style={{ minHeight: "300px", marginTop: "0" }}>
      <Row className="m-4">
        <Col xl={6}>
          <h3>Reccuring Payments</h3>

          {recurringPayments && recurringPayments.length > 0 ? (
            <>
              <h4>---- Active</h4>
              {activePayments.map(recurringPayment => (
                <RecurringPaymentItem
                  recurringPayment={recurringPayment}
                  key={recurringPayment.id}
                />
              ))}
              <h4>---- Canceled</h4>
              {canceledPayments.map(recurringPayment => (
                <RecurringPaymentItem
                  recurringPayment={recurringPayment}
                  canceled={true}
                  key={recurringPayment.id}
                />
              ))}
            </>
          ) : (
            <h5>No recurring payments found</h5>
          )}
          <div className="card-section-divider" />
        </Col>
      </Row>
    </Card>
  )
}

const RecurringPaymentItem = ({ recurringPayment, canceled }) => {
  return (
    <div style={{ opacity: canceled ? 0.5 : 1 }}>
      <div className="card-section-divider" />
      <div className="customer-recurring-container">
        <div className="details-row">
          <span className="details-header">Recurring ID:</span>
          <span className="details-value">{recurringPayment.id}</span>
        </div>
        {recurringPayment.accountNumber && (
          <div className="details-row">
            <span className="details-header">Account number:</span>
            <span className="details-value">
              {recurringPayment.accountNumber}
            </span>
          </div>
        )}
        {recurringPayment.card && (
          <div className="details-row">
            <span className="details-header">Card:</span>
            <div style={{ paddingLeft: 24 }}>
              <div>
                <span className="details-header">- Card ID:</span>
                <span className="details-value">
                  {recurringPayment.card.id}
                </span>
              </div>
              <div>
                <span className="details-header">- Brand:</span>
                <span className="details-value">
                  {recurringPayment.card.cardBrand}
                </span>
              </div>
              <div>
                <span className="details-header">- Expire:</span>
                <span className="details-value">
                  {recurringPayment.card.expire}
                </span>
              </div>
              <div>
                <span className="details-header">- Holder Name :</span>
                <span className="details-value">
                  {recurringPayment.card.holderName}
                </span>
              </div>
              <div>
                <span className="details-header">- Obfuscated PAN:</span>
                <span className="details-value">
                  {recurringPayment.card.obfuscatedPAN}
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="details-row">
          <span className="details-header">Amount:</span>
          <span className="details-value">{recurringPayment.amount}</span>
        </div>
        <div className="details-row">
          <span className="details-header">Created:</span>
          <span className="details-value">
            {Utils.getUtcInLocalDateTime(recurringPayment.created)}
          </span>
        </div>
        <div className="details-row">
          <span className="details-header">Crypto:</span>
          <span className="details-value">
            {recurringPayment.cryptoCurrency}
          </span>
        </div>
        <div className="details-row">
          <span className="details-header">Fiat currency:</span>
          <span className="details-value">{recurringPayment.currency}</span>
        </div>
        <div className="details-row">
          <span className="details-header">Day of execution:</span>
          <span className="details-value">
            {recurringPayment.dayOfExecution}
          </span>
        </div>
        <div className="details-row">
          <span className="details-header">Next execution day:</span>
          <span className="details-value">
            {recurringPayment.nextExecutionDay}
          </span>
        </div>
        <div className="details-row">
          <span className="details-header">Frequency:</span>
          <span className="details-value">{recurringPayment.frequency}</span>
        </div>
        <div className="details-row">
          <span className="details-header">Remittance information:</span>
          <span className="details-value">
            {recurringPayment.remittanceInformation}
          </span>
        </div>
        <div className="details-row">
          <span className="details-header">Last payment failed:</span>
          <span className="details-value">
            {recurringPayment.lastPaymentFailed ? (
              <span style={{ color: "red" }}>
                {recurringPayment.lastPaymentFailed}
              </span>
            ) : (
              "No"
            )}
          </span>
        </div>
      </div>
    </div>
  )
}

export default CustomerRecurringPayments
