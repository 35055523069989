/**
 * Contains endpoints for Safello Backoffice
 */

import API from "../Api/Api.js"

const backOfficeApi = new API({
  url: process.env.REACT_APP_API_BASE_URL,
})
backOfficeApi.createEntity({
  name: "login",
  path: "backoffice/login",
})
backOfficeApi.createEntity({
  name: "logout",
  path: "backoffice/logout",
})

// Dashboards
//Financial
backOfficeApi.createEntity({
  name: "statsFinancialNetRevenue",
  path: "stats/charts/financial/net-revenue",
})
backOfficeApi.createEntity({
  name: "statsFinancialRevenue",
  path: "stats/charts/financial/revenue",
})
backOfficeApi.createEntity({
  name: "statsFinancialRevenueType",
  path: "stats/charts/financial/revenue-type",
})
backOfficeApi.createEntity({
  name: "statsFinancialCumulativeRevenue",
  path: "stats/charts/financial/cumulative-revenue",
})
backOfficeApi.createEntity({
  name: "statsFinancialCumulativeTurnover",
  path: "stats/charts/financial/cumulative-turnover",
})
backOfficeApi.createEntity({
  name: "statsFinancialAverageOrderAmount",
  path: "stats/charts/financial/average-order-amount",
})
backOfficeApi.createEntity({
  name: "statsFinancialAverageOrderAmountAll",
  path: "stats/charts/financial/average-order-amount-all",
})
backOfficeApi.createEntity({
  name: "statsFinancialTurnover",
  path: "stats/charts/financial/turnover",
})
backOfficeApi.createEntity({
  name: "statsFinancialChannelVolume",
  path: "stats/charts/financial/channel-volume",
})
backOfficeApi.createEntity({
  name: "statsFinancialTurnoverType",
  path: "stats/charts/financial/turnover-type",
})
backOfficeApi.createEntity({
  name: "statsFinancialOrdersAvgPerChannel",
  path: "stats/charts/financial/orders-avg-by-channel",
})
backOfficeApi.createEntity({
  name: "statsFinancialRevenueByCountry",
  path: "stats/charts/financial/revenue-by-country",
})
backOfficeApi.createEntity({
  name: "statsFinancialCards",
  path: "stats/cards/financial",
})

//Business
backOfficeApi.createEntity({
  name: "statsBusinessCards",
  path: "stats/cards/business",
})
backOfficeApi.createEntity({
  name: "statsBusinessRevenue",
  path: "stats/charts/business/revenue",
})
backOfficeApi.createEntity({
  name: "statsBusinessVolume",
  path: "stats/charts/business/volume",
})
backOfficeApi.createEntity({
  name: "statsBusinessOrders",
  path: "stats/charts/business/orders",
})
backOfficeApi.createEntity({
  name: "statsBusinessSignUps",
  path: "stats/charts/business/sign-ups",
})

//Metrics
backOfficeApi.createEntity({
  name: "statsMetricsCards",
  path: "stats/cards/metrics",
})
backOfficeApi.createEntity({
  name: "statsMetricsOrders",
  path: "stats/charts/metrics/orders",
})
backOfficeApi.createEntity({
  name: "statsMetricsCryotoOrders",
  path: "stats/charts/metrics/cryptoorders",
})
backOfficeApi.createEntity({
  name: "statsMetricsCryotoTxFee",
  path: "stats/charts/metrics/crypto-tx-fee",
})
backOfficeApi.createEntity({
  name: "statsMetricsRegistrations",
  path: "stats/charts/metrics/registrations",
})
backOfficeApi.createEntity({
  name: "statsMetricsCumulativeRegistrations",
  path: "stats/charts/metrics/registrations-cumulative",
})
backOfficeApi.createEntity({
  name: "statsMetricsActiveUsers",
  path: "stats/charts/metrics/active-users",
})
backOfficeApi.createEntity({
  name: "statsMetricsFirstTimeAndRecurring",
  path: "stats/charts/metrics/first-time-and-recurring",
})
backOfficeApi.createEntity({
  name: "statsMetricsVerificationApproved",
  path: "stats/charts/metrics/verification-approved",
})
backOfficeApi.createEntity({
  name: "statsMetricsOrdersByCountry",
  path: "stats/charts/metrics/orders-by-country",
})
backOfficeApi.createEntity({
  name: "statsMetricsOrdersStoppedReasons",
  path: "stats/charts/metrics/orders-stopped-reasons",
})
backOfficeApi.createEntity({
  name: "statsMetricsOrdersStoppedPercentage",
  path: "stats/charts/metrics/all-orders-stopped-percentage",
})
backOfficeApi.createEntity({
  name: "statsMetricsOrdersIssuedCompletedRefunded",
  path: "stats/charts/metrics/orders-issued-completed-refunded",
})

backOfficeApi.createEntity({
  name: "statsPartnerRevenue",
  path: "api/stats/charts/partner/revenue",
})
backOfficeApi.createEntity({
  name: "statsPartnerOrders",
  path: "api/stats/charts/partner/orders",
})
backOfficeApi.createEntity({
  name: "statsPartnerOrderAmountAvg",
  path: "api/stats/charts/partner/order-amount-average",
})
backOfficeApi.createEntity({
  name: "statsPartnerRegistrations",
  path: "stats/charts/partner/registrations",
})
backOfficeApi.createEntity({
  name: "statsPartnerLive",
  path: "stats/charts/partner/live",
})
backOfficeApi.createEntity({
  name: "statsWalletCountStats",
  path: "stats/charts/wallet/count-stats",
})
backOfficeApi.createEntity({
  name: "statsWalletVolumeStats",
  path: "stats/charts/wallet/volume-stats",
})
backOfficeApi.createEntity({
  name: "statsTreasuryTurnover",
  path: "stats/charts/treasury/turnover",
})
backOfficeApi.createEntity({
  name: "statsTreasuryWalletBalances",
  path: "stats/charts/treasury/wallet-balances",
})
backOfficeApi.createEntity({
  name: "statsCashOnHandAccountBalances",
  path: "stats/charts/cash-on-hand/account-balances",
})
backOfficeApi.createEntity({
  name: "statsComplianceFraudOrders",
  path: "stats/charts/compliance/fraud-orders",
})
backOfficeApi.createEntity({
  name: "statsComplianceFraudOrderPercentage",
  path: "stats/charts/compliance/fraud-orders-percentage",
})
backOfficeApi.createEntity({
  name: "statsComplianceFraudOrderAmount",
  path: "stats/charts/compliance/fraud-orders-amount",
})
backOfficeApi.createEntity({
  name: "statsComplianceFraudOrderAmountPercentage",
  path: "stats/charts/compliance/fraud-orders-amount-percentage",
})
backOfficeApi.createEntity({
  name: "statsComplianceFraudOrderAmountPercentageCompleted",
  path: "stats/charts/compliance/fraud-orders-amount-percentage-completed",
})
backOfficeApi.createEntity({
  name: "statsComplianceFrozenAmount",
  path: "stats/charts/compliance/frozen-orders-amount",
})
backOfficeApi.createEntity({
  name: "statsComplianceTaggedOrders",
  path: "stats/charts/compliance/tagged-orders",
})
backOfficeApi.createEntity({
  name: "statsComplianceTaggedOrdersVolume",
  path: "stats/charts/compliance/tagged-orders-volume",
})
backOfficeApi.createEntity({
  name: "statsComplianceCards",
  path: "stats/cards/compliance",
})
backOfficeApi.createEntity({
  name: "statsCompliancePies",
  path: "stats/pies/compliance",
})
backOfficeApi.createEntity({
  name: "statsStatisticsAverageSignupAge",
  path: "stats/charts/statistics/average-signup-age",
})
backOfficeApi.createEntity({
  name: "statsStatisticsOrderChannelAverageTime",
  path: "stats/charts/statistics/order-channel-average-time",
})
backOfficeApi.createEntity({
  name: "statsStatisticsSwishDistribution",
  path: "stats/charts/statistics/swish-distribution",
})
backOfficeApi.createEntity({
  name: "statsStatisticsSwishErrorReasons",
  path: "stats/charts/statistics/swish-error-reasons",
})
backOfficeApi.createEntity({
  name: "statsStatisticsPies",
  path: "stats/pies/statistics",
})
backOfficeApi.createEntity({
  name: "statsStatisticsAgeGroupSpending",
  path: "stats/bars/statistics/age-group-spending",
})
backOfficeApi.createEntity({
  name: "statsDemographic",
  path: "stats/demographic",
})
backOfficeApi.createEntity({
  name: "downloadAccountingUnprocessedOrders",
  path: "frontend/unprocessed-orders-csv",
})
backOfficeApi.createEntity({
  name: "downloadAccountingOrders",
  path: "frontend/accounting-orders-csv",
})
backOfficeApi.createEntity({
  name: "downloadAccountingCryptoOrders",
  path: "frontend/accounting-crypto-orders-csv",
})
backOfficeApi.createEntity({
  name: "downloadOutstandingWalletDebt",
  path: "wallet-report/generate",
})
backOfficeApi.createEntity({
  name: "downloadCustomerOutstandingWalletDebt",
  path: "wallet-report/generate-customer",
})
backOfficeApi.createEntity({
  name: "downloadAccountingCryptoActions",
  path: "frontend/accounting-crypto-actions-csv",
})
backOfficeApi.createEntity({
  name: "downloadJournalVoucher",
  path: "accounting/swish_report?startDateInclusive={from}&endDateExclusive={to}",
})
backOfficeApi.createEntity({
  name: "downloadInventoryReport",
  path: "accounting/reports/fifo",
})
backOfficeApi.createEntity({
  name: "downloadWithdrawalSummary",
  path: "accounting/reports/withdrawal-summary",
})
backOfficeApi.createEntity({
  name: "downloadActiveCustomersCsv",
  path: "frontend/active-customers-csv?days={days}",
})

//Wallet Statistics
backOfficeApi.createEntity({
  name: "statsWalletCards",
  path: "stats/cards/wallet",
})
backOfficeApi.createEntity({
  name: "statsWalletCountChart",
  path: "stats/charts/treasury/wallet-active",
})
backOfficeApi.createEntity({
  name: "statsWalletAum",
  path: "stats/charts/treasury/wallet-assets-under-management",
})

//Mobile
backOfficeApi.createEntity({
  name: "sendMobilePushNotificationV2",
  path: "mobile/v2/send-push-notification",
})
backOfficeApi.createEntity({
  name: "getLatestNotifications",
  path: "mobile/latest-notifications",
})

//Mobile app
backOfficeApi.createEntity({
  name: "statsMobileAppOrders",
  path: "stats/charts/mobile-app/orders",
})
backOfficeApi.createEntity({
  name: "statsMobileAppVolume",
  path: "stats/charts/mobile-app/volume",
})
backOfficeApi.createEntity({
  name: "statsMobileAppRevenue",
  path: "stats/charts/mobile-app/revenue",
})
backOfficeApi.createEntity({
  name: "statsMobileAppAverageAmount",
  path: "stats/charts/mobile-app/average-amount",
})
backOfficeApi.createEntity({
  name: "statsMobileAppCards",
  path: "stats/cards/mobile-app",
})

// Support dashboard
backOfficeApi.createEntity({
  name: "buyOrdersStats",
  path: "frontend/orders/buy/stats",
})
backOfficeApi.createEntity({
  name: "sellOrdersStats",
  path: "frontend/orders/sell/stats",
})
backOfficeApi.createEntity({
  name: "sellOrdersStatsByStatus",
  path: "frontend/orders/sell/stats/{status}?crypto={crypto}",
})
backOfficeApi.createEntity({
  name: "walletOrdersStats",
  path: "frontend/wallet/stats",
})
backOfficeApi.createEntity({
  name: "paymentNotificationStats",
  path: "frontend/orders/paymentnotification/stats",
})

// Wallet
backOfficeApi.createEntity({
  name: "walletTransactions",
  path: "frontend/wallet/transactions",
})
backOfficeApi.createEntity({
  name: "walletSwapTransactions",
  path: "frontend/orders/swap",
})
backOfficeApi.createEntity({
  name: "walletSwapTransactionsCustomer",
  path: "frontend/orders/swap/customer/{id}",
})
backOfficeApi.createEntity({
  name: "walletSwapTransaction",
  path: "frontend/orders/swap/{id}",
})
backOfficeApi.createEntity({
  name: "walletTransactionDetails",
  path: "frontend/wallet/transaction/{id}",
})
backOfficeApi.createEntity({
  name: "getCustomerWalletBalance",
  path: "frontend/wallet/balance/{id}",
})
backOfficeApi.createEntity({
  name: "walletHandleTransaction",
  path: "wallet/handle-transaction/{id}",
})
backOfficeApi.createEntity({
  name: "searchWalletTransactions",
  path: "frontend/wallet/transactions/search",
})
backOfficeApi.createEntity({
  name: "banlistWalletTransactionsAddress",
  path: "wallet/banlist-transactions-address",
})
backOfficeApi.createEntity({
  name: "bumpWalletTransaction",
  path: "wallet/bump-transaction/{id}",
})

// Orders
backOfficeApi.createEntity({
  name: "buyOrders",
  path: "frontend/orders/buy",
})
backOfficeApi.createEntity({
  name: "customerBuyOrders",
  path: "frontend/orders/buy/customer/{id}",
})
backOfficeApi.createEntity({
  name: "customerSellOrders",
  path: "frontend/orders/sell/customer/{id}",
})
backOfficeApi.createEntity({
  name: "buyOrderDetails",
  path: "frontend/orders/buy/{id}",
})
backOfficeApi.createEntity({
  name: "buyOrderPaymentNotification",
  path: "frontend/orders/buy/{id}/paymentnotification",
})
backOfficeApi.createEntity({
  name: "searchBuyOrder",
  path: "frontend/orders/buy/search",
})
backOfficeApi.createEntity({
  name: "sellOrders",
  path: "frontend/orders/sell",
})
backOfficeApi.createEntity({
  name: "sellOrderDetails",
  path: "frontend/orders/sell/{id}",
})
backOfficeApi.createEntity({
  name: "searchSellOrder",
  path: "frontend/orders/sell/search",
})
backOfficeApi.createEntity({
  name: "paymentChannels",
  path: "data/paymentChannels",
})
backOfficeApi.createEntity({
  name: "tradeBuyOrder",
  path: "trader/execute-buy-trade",
})
backOfficeApi.createEntity({
  name: "tradeSellOrder",
  path: "trader/execute_sell_trade",
})
backOfficeApi.createEntity({
  name: "bumpTransaction",
  path: "buy-order/{id}/bump-transaction",
})
backOfficeApi.createEntity({
  name: "freezeBuyOrder",
  path: "buy-order/{id}/freeze",
})
backOfficeApi.createEntity({
  name: "freezeSellOrder",
  path: "sell-order/{id}/freeze",
})
backOfficeApi.createEntity({
  name: "unfreezeBuyOrder",
  path: "buy-order/{id}/unfreeze",
})
backOfficeApi.createEntity({
  name: "unfreezeSellOrder",
  path: "sell-order/{id}/unfreeze",
})
backOfficeApi.createEntity({
  name: "sendToPaymentGateway",
  path: "order/{orderId}/send-to-payment-gateway",
})
backOfficeApi.createEntity({
  name: "editBuyOrder",
  path: "buy-order/{id}/edit",
})
backOfficeApi.createEntity({
  name: "editSellOrder",
  path: "sell-order/{id}/edit",
})
backOfficeApi.createEntity({
  name: "createSellOrder",
  path: "order/create-sell-order",
})
backOfficeApi.createEntity({
  name: "createInternalTransfer",
  path: "wallet/internal-transfer",
})
backOfficeApi.createEntity({
  name: "banlistOrdersAddress",
  path: "orders/banlist-orders-address",
})

backOfficeApi.createEntity({
  name: "refundBuyOrder",
  path: "orders/{id}/refund",
})
backOfficeApi.createEntity({
  name: "refundSellOrder",
  path: "orders/{id}/refund-sell-order",
})
backOfficeApi.createEntity({
  name: "refundWalletSellOrder",
  path: "orders/{id}/refund-wallet-sell-order",
})
backOfficeApi.createEntity({
  name: "deleteOrder",
  path: "order/lazydelete",
})
backOfficeApi.createEntity({
  name: "orderCardTransactions",
  path: "orders/{orderId}/cardtransaction",
})
backOfficeApi.createEntity({
  name: "orderLimits",
  path: "orders/limits",
})
backOfficeApi.createEntity({
  name: "editOrderLimits",
  path: "orders/limits/edit",
})
backOfficeApi.createEntity({
  name: "buyOrderBitstampTransactions",
  path: "bitstamp/buy-order-transactions",
})
backOfficeApi.createEntity({
  name: "buyOrderEnigmaTransaction",
  path: "enigma/transaction",
})
backOfficeApi.createEntity({
  name: "buyOrderBlockchainTransaction",
  path: "blockchain/transaction",
})
backOfficeApi.createEntity({
  name: "sellOrderBitstampTransactions",
  path: "bitstamp/sell-order-transactions",
})
backOfficeApi.createEntity({
  name: "sellOrderBlockchainTransactions",
  path: "blockchain/sell-transaction",
})
backOfficeApi.createEntity({
  name: "sendSellOrder",
  path: "order/sendsellorder",
})
backOfficeApi.createEntity({
  name: "customerAggregatedTransactions",
  path: "frontend/customer/{customerId}/aggregated-transactions",
})

// Chainalysis
backOfficeApi.createEntity({
  name: "chainalysisUserKyt",
  path: "chainalysis/get-user/kyt",
})
backOfficeApi.createEntity({
  name: "chainalysisOrder",
  path: "chainalysis/get-order/{id}",
})
backOfficeApi.createEntity({
  name: "chainalysisTransaction",
  path: "wallet/kyt/{id}",
})
backOfficeApi.createEntity({
  name: "chainalysisGetAlerts",
  path: "chainalysis/get-alerts",
})
backOfficeApi.createEntity({
  name: "chainalysisWalletTransactionExposure",
  path: "chainalysis/wallet-transfer-details",
})

// Treasury
backOfficeApi.createEntity({
  name: "gatewayStatus",
  path: "payment/gateway_status",
})
backOfficeApi.createEntity({
  name: "treasuryAverages",
  path: "data/treasuryAverages",
})
backOfficeApi.createEntity({
  name: "cryptoBought",
  path: "data/cryptoBought",
})
backOfficeApi.createEntity({
  name: "bitstampBalance",
  path: "bitstamp/balance",
})
backOfficeApi.createEntity({
  name: "getFireblocksBalance",
  path: "fireblocks/balances",
})
backOfficeApi.createEntity({
  name: "bitstampTicker",
  path: "bitstamp/ticker",
})
backOfficeApi.createEntity({
  name: "executeGatewayPayments",
  path: "payment/execute",
})
backOfficeApi.createEntity({
  name: "getQuote",
  path: "api/account/get-quote",
})
backOfficeApi.createEntity({
  name: "getEnigmaBalances",
  path: "enigma/balances",
})
backOfficeApi.createEntity({
  name: "getBlockchainBalances",
  path: "blockchain/balances",
})
backOfficeApi.createEntity({
  name: "getWalletLiabilities",
  path: "wallet/liabilities",
})
backOfficeApi.createEntity({
  name: "liquidity",
  path: "trader/liquidity",
})
backOfficeApi.createEntity({
  name: "inProcessTransactions",
  path: "exchange/processing-transactions",
})
backOfficeApi.createEntity({
  name: "getWithdrawalDepositLiabilities",
  path: "frontend/wallet/withdrawal-deposit-liabilities",
})
backOfficeApi.createEntity({
  name: "getWithdrawalDepositLiabilitiesV2",
  path: "frontend/v2/wallet/withdrawal-deposit-liabilities",
})
// Customers
backOfficeApi.createEntity({
  name: "customers",
  path: "frontend/customers",
})
backOfficeApi.createEntity({
  name: "customerDetails",
  path: "frontend/customers/{id}",
})
backOfficeApi.createEntity({
  name: "searchCustomers",
  path: "frontend/customers/search",
})
backOfficeApi.createEntity({
  name: "customerOrderStats",
  path: "frontend/customer/{id}/order-stats",
})
backOfficeApi.createEntity({
  name: "gdprRemovalRequest",
  path: "customer/{id}/gdpr-removal-request",
})
backOfficeApi.createEntity({
  name: "removeGdprRemovalRequest",
  path: "customer/{id}/remove-gdpr-removal-request",
})
backOfficeApi.createEntity({
  name: "getCustomerAccesses",
  path: "customer/{id}/accesses",
})
backOfficeApi.createEntity({
  name: "getCustomerAccessesCorporate",
  path: "customer/{id}/accesses/corporate",
})
backOfficeApi.createEntity({
  name: "addCustomerAccess",
  path: "customer/{id}/accesses/corporate/add",
})
backOfficeApi.createEntity({
  name: "disableCustomerAccess",
  path: "customer/{id}/accesses/corporate/remove",
})
backOfficeApi.createEntity({
  name: "freezeReview",
  path: "customer/{id}/freeze-review",
})
backOfficeApi.createEntity({
  name: "exportCustomer",
  path: "customer/{id}/export",
})
backOfficeApi.createEntity({
  name: "editCustomer",
  path: "customer/{id}/edit",
})
backOfficeApi.createEntity({
  name: "changeRiskProfile",
  path: "customer/{id}/risk-profile",
})
backOfficeApi.createEntity({
  name: "customerKyc",
  path: "frontend/customer/{id}/kyc-answers",
})
backOfficeApi.createEntity({
  name: "deleteKyc",
  path: "customer/{customerId}/kyc/delete",
})
backOfficeApi.createEntity({
  name: "deleteAllKyc",
  path: "customer/{customerId}/delete-all-kyc",
})
backOfficeApi.createEntity({
  name: "getCustomerTags",
  path: "frontend/customers/tags",
})
backOfficeApi.createEntity({
  name: "addCustomerTag",
  path: "customer/{customerId}/add-tag",
})
backOfficeApi.createEntity({
  name: "removeCustomerTag",
  path: "customer/{customerId}/remove-tag",
})
backOfficeApi.createEntity({
  name: "customerBankAccounts",
  path: "customer/{customerId}/bank-accounts",
})
backOfficeApi.createEntity({
  name: "customerImages",
  path: "customer/images",
})
backOfficeApi.createEntity({
  name: "uploadDocument",
  path: "image/upload",
})
backOfficeApi.createEntity({
  name: "deleteDocument",
  path: "image/{id}",
})
backOfficeApi.createEntity({
  name: "selectDefaultBankAccount",
  path: "customer/{customerId}/set-default-bank-account",
})
backOfficeApi.createEntity({
  name: "deleteBankAccount",
  path: "customer/{customerId}/delete-bank-account",
})
backOfficeApi.createEntity({
  name: "hideBankAccount",
  path: "customer/{customerId}/hide-bank-account",
})
backOfficeApi.createEntity({
  name: "addBankAccount",
  path: "customer/{customerId}/add-bank-account",
})
backOfficeApi.createEntity({
  name: "editBankAccount",
  path: "customer/{customerId}/edit-bank-account",
})
backOfficeApi.createEntity({
  name: "getAllBanks",
  path: "bank/all",
})
backOfficeApi.createEntity({
  name: "getCustomerLogins",
  path: "customer/{customerId}/logins",
})
backOfficeApi.createEntity({
  name: "createUpdateIntercomUser",
  path: "customer/{customerId}/intercom/create-or-update",
})
backOfficeApi.createEntity({
  name: "getYearlyLimit",
  path: "customer/{customerId}/yearly-limit",
})
backOfficeApi.createEntity({
  name: "getRecurringPayments",
  path: "customer/{customerId}/recurring-payments",
})
backOfficeApi.createEntity({
  name: "getRecurringPaymentByOrderId",
  path: "orders/{orderId}/recurring-payment",
})
backOfficeApi.createEntity({
  name: "updateYearlyLimit",
  path: "customer/{customerId}/update-yearly-limit",
})
backOfficeApi.createEntity({
  name: "resetYearlyLimit",
  path: "customer/{customerId}/reset-yearly-limit",
})
backOfficeApi.createEntity({
  name: "customerTinkData",
  path: "customer/{customerId}/tink-data",
})
backOfficeApi.createEntity({
  name: "customerTinkTransactions",
  path: "customer/{customerId}/tink-transactions?tinkUser={tinkUserId}",
})
backOfficeApi.createEntity({
  name: "customerLimits",
  path: "customer/{customerId}/limits",
})
backOfficeApi.createEntity({
  name: "customerChannelLimits",
  path: "customer/{customerId}/channel-limits",
})
backOfficeApi.createEntity({
  name: "customerRunPepSanction",
  path: "customer/{customerId}/run-pep-sanction",
})
backOfficeApi.createEntity({
  name: "customerPepSanction",
  path: "customer/{customerId}/all-pepsanction",
})
backOfficeApi.createEntity({
  name: "customerPepSanctionMatchDetails",
  path: "customer/{customerId}/pepsanction/match-details",
})
backOfficeApi.createEntity({
  name: "customerReviewPepSanction",
  path: "customer/{customerId}/review-pepsanction",
})
backOfficeApi.createEntity({
  name: "customerReviewStatus",
  path: "customer/{customerId}/review-status",
})
backOfficeApi.createEntity({
  name: "customerReview",
  path: "customer/{customerId}/review",
})
backOfficeApi.createEntity({
  name: "offboardCustomer",
  path: "customer/{customerId}/offboard",
})
backOfficeApi.createEntity({
  name: "customerProtected",
  path: "customer/{customerId}/protected",
})
backOfficeApi.createEntity({
  name: "approveCorporate",
  path: "customer/{customerId}/approve-corporate",
})
backOfficeApi.createEntity({
  name: "rejectCorporate",
  path: "customer/{customerId}/reject-corporate",
})
backOfficeApi.createEntity({
  name: "customerSellPofs",
  path: "customer/{customerId}/sell-pofs",
})
backOfficeApi.createEntity({
  name: "customerAddSellPof",
  path: "customer/{customerId}/add-sell-pof",
})
backOfficeApi.createEntity({
  name: "forceStopOrders",
  path: "customer/{customerId}/force-stop-orders",
})
backOfficeApi.createEntity({
  name: "customerSafeEnvironment",
  path: "customer/{customerId}/safe-environment",
})
backOfficeApi.createEntity({
  name: "customerFeeLevelData",
  path: "customer/{customerId}/fee-level",
})
backOfficeApi.createEntity({
  name: "bumpCustomerTier",
  path: "customer/{id}/bump-tier",
})
backOfficeApi.createEntity({
  name: "pocLimits",
  path: "customer/{customerId}/sell-limits",
})

// Partners
backOfficeApi.createEntity({
  name: "partners",
  path: "frontend/partners",
})
backOfficeApi.createEntity({
  name: "customerVerifications",
  path: "frontend/customer/{id}/verifications",
})
backOfficeApi.createEntity({
  name: "activatePartner",
  path: "partner/{id}/activate",
})
backOfficeApi.createEntity({
  name: "deactivatePartner",
  path: "partner/{id}/deactivate",
})
backOfficeApi.createEntity({
  name: "getPartner",
  path: "partner/{id}",
})
backOfficeApi.createEntity({
  name: "editPartner",
  path: "partner/{id}/edit",
})
backOfficeApi.createEntity({
  name: "addNewAppApi",
  path: "partner/{id}/add-new-app-api",
})
backOfficeApi.createEntity({
  name: "editAppApi",
  path: "partner/{id}/edit-app-api/{appId}",
})
backOfficeApi.createEntity({
  name: "newFeeRevision",
  path: "partner/{id}/new-fee-revision/{appId}",
})

//Common (Customers/Orders/Wallet_transactions)
backOfficeApi.createEntity({
  name: "getTags",
  path: "comment-tag/tags",
})
backOfficeApi.createEntity({
  name: "addTag",
  path: "comment-tag/{id}/add-tag",
})
backOfficeApi.createEntity({
  name: "removeTag",
  path: "comment-tag/{id}/remove-tag",
})
backOfficeApi.createEntity({
  name: "batchAddTags",
  path: "comment-tag/batch-add-tags",
})
backOfficeApi.createEntity({
  name: "batchRemoveTags",
  path: "comment-tag/batch-remove-tags",
})
backOfficeApi.createEntity({
  name: "comments",
  path: "comment-tag/{id}/comments",
})
backOfficeApi.createEntity({
  name: "comment",
  path: "comment-tag/{id}/comment",
})
backOfficeApi.createEntity({
  name: "archiveComment",
  path: "comment-tag/comment/{commentId}/archive",
})

// Admin
backOfficeApi.createEntity({
  name: "adminUsers",
  path: "frontend/users/admin",
})
backOfficeApi.createEntity({
  name: "getUser",
  path: "frontend/users/{id}",
})
backOfficeApi.createEntity({
  name: "getAllPermissions",
  path: "frontend/permissions",
})
backOfficeApi.createEntity({
  name: "addUser",
  path: "user/add",
})
backOfficeApi.createEntity({
  name: "updatePermissions",
  path: "user/{id}/permissions",
})
backOfficeApi.createEntity({
  name: "inactivate",
  path: "user/{id}/inactivate",
})
backOfficeApi.createEntity({
  name: "resetPassword",
  path: "user/{id}/reset-password",
})
backOfficeApi.createEntity({
  name: "updatePasswordReset",
  path: "api/user/update-password-reset",
})

// Country
backOfficeApi.createEntity({
  name: "countries",
  path: "frontend/countries",
})

// Channel
backOfficeApi.createEntity({
  name: "channels",
  path: "channel/all",
})

//Transactions
backOfficeApi.createEntity({
  name: "listPaymentNotifications",
  path: "paymentnotification/list",
})
backOfficeApi.createEntity({
  name: "searchPaymentNotifications",
  path: "paymentnotification/search",
})
backOfficeApi.createEntity({
  name: "paymentNotificationUnmatched",
  path: "paymentnotification/unmatched",
})
backOfficeApi.createEntity({
  name: "paymentNotification",
  path: "paymentnotification/{id}",
})
backOfficeApi.createEntity({
  name: "matchingOrders",
  path: "paymentnotification/{id}/matching-orders",
})
backOfficeApi.createEntity({
  name: "connectPnToOrder",
  path: "paymentnotification/connect",
})
backOfficeApi.createEntity({
  name: "refundPaymentNotification",
  path: "paymentnotification/{id}/refund",
})
backOfficeApi.createEntity({
  name: "deletePaymentNotification",
  path: "paymentnotification/{id}/delete",
})

backOfficeApi.createEntity({
  name: "searchComments",
  path: "frontend/comments/search",
})

backOfficeApi.createEntity({
  name: "getReviewList",
  path: "frontend/customers/review-list",
})

//Reporting
backOfficeApi.createEntity({
  name: "generatePoliceReport",
  path: "reporting/generate-report",
})

//Whistleblower
backOfficeApi.createEntity({
  name: "whistleblowerRecipients",
  path: "whistleblower/recipients",
})
backOfficeApi.createEntity({
  name: "whistleblowerAdd",
  path: "whistleblower/add",
})

//Accounting
backOfficeApi.createEntity({
  name: "accountingUnmatchedReport",
  path: "accounting/reports/unmatched",
})
backOfficeApi.createEntity({
  name: "accountingMoveToExpense",
  path: "accounting/move_to_expense",
})

//Tools
backOfficeApi.createEntity({
  name: "toolsGetBlacklistedPnrs",
  path: "compliance/get-blacklisted-pnrs",
})
backOfficeApi.createEntity({
  name: "toolsAddPnrToBlacklist",
  path: "compliance/add-blacklist-pnr",
})
backOfficeApi.createEntity({
  name: "toolsGetBlacklistedCryptoAddresses",
  path: "compliance/get-blacklisted-addresses",
})
backOfficeApi.createEntity({
  name: "toolsAddCryptoAddressToBlacklist",
  path: "compliance/add-blacklist-address",
})
backOfficeApi.createEntity({
  name: "toolsIsOurCryptoAddress",
  path: "api/orders/own-address/{crypto}/{cryptoAddress}",
})
backOfficeApi.createEntity({
  name: "getOrderQuote",
  path: "api/orders/get-quote",
})

// Discounts
backOfficeApi.createEntity({
  name: "getCustomerDiscounts",
  path: "customer/{customerId}/discount-fees",
})
backOfficeApi.createEntity({
  name: "addCustomerDiscount",
  path: "customer/{customerId}/discount-fee",
})
backOfficeApi.createEntity({
  name: "deactivateCustomerDiscount",
  path: "customer/{customerId}/discount-fee/deactivate",
})
backOfficeApi.createEntity({
  name: "getDiscount",
  path: "discount/{discountFeeId}",
})

//Customer wallets
backOfficeApi.createEntity({
  name: "getCustomerWallets",
  path: "customer/{customerId}/wallets",
})
backOfficeApi.createEntity({
  name: "adminFeeTransfer",
  path: "admin-fee-transfer",
})

//Tickers
backOfficeApi.createEntity({
  name: "getTickers",
  path: "frontend/tickers",
})

//Payouts
backOfficeApi.createEntity({
  name: "getPayoutMethods",
  path: "payout/methods",
})
backOfficeApi.createEntity({
  name: "getPayoutChannels",
  path: "payout/channels",
})
backOfficeApi.createEntity({
  name: "queueSellOrderPayout",
  path: "payout/queue/{orderId}",
})
backOfficeApi.createEntity({
  name: "queueManySellOrders",
  path: "payout/queue",
})
backOfficeApi.createEntity({
  name: "listPayouts",
  path: "payout/list/{payoutChannelId}/{status}",
})
backOfficeApi.createEntity({
  name: "generateIso20022PayoutFile",
  path: "payout/generate-payout-iso20022-file",
})
backOfficeApi.createEntity({
  name: "markIso20022PayoutFileCompleted",
  path: "payout/mark-iso2022-file-as-completed",
})
backOfficeApi.createEntity({
  name: "handlePayouts",
  path: "payout/handle",
})
//Feature Flag
backOfficeApi.createEntity({
  name: "getAllFeatureFlags",
  path: "feature-flag/all",
})
backOfficeApi.createEntity({
  name: "addCustomerFeatureFlag",
  path: "feature-flag/{customerId}/add/{flagKey}",
})
backOfficeApi.createEntity({
  name: "removeCustomerFeatureFlag",
  path: "feature-flag/{customerId}/remove/{flagKey}",
})
backOfficeApi.createEntity({
  name: "getCustomerFeatureFlags",
  path: "feature-flag/{customerId}",
})
backOfficeApi.createEntity({
  name: "createFeatureFlag",
  path: "feature-flag/create",
})
backOfficeApi.createEntity({
  name: "deleteFeatureFlag",
  path: "feature-flag/{flagKey}/delete",
})
backOfficeApi.createEntity({
  name: "enableFeatureFlag",
  path: "feature-flag/{flagKey}/enable",
})
// Operations
backOfficeApi.createEntity({
  name: "editCryptoFunctionalities",
  path: "operations/crypto/edit-functionalities",
})
backOfficeApi.createEntity({
  name: "getAllTradingPairs",
  path: "operations/trading-pair/all",
})
backOfficeApi.createEntity({
  name: "setTradingPairActive",
  path: "operations/trading-pair/active",
})
// Misc
backOfficeApi.createEntity({
  name: "customerTakeover",
  path: "staging/pnr-takeover/{customerId}",
})
backOfficeApi.createEntity({
  name: "getCryptos",
  path: "frontend/crypto",
})
backOfficeApi.createEntity({
  name: "getFiatRate",
  path: "frontend/rate/current",
})

export default backOfficeApi
