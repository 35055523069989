import React, { useState, useEffect, useCallback } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Row, Col } from "reactstrap"
import BuyOrderInfoArea from "./BuyOrderInfoArea"
import BuyOrderActionsArea from "./BuyOrderActionsArea"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import CommentArea from "../../Common/CommentArea"
import StorageUtils from "../../Common/StorageUtils"
import EditTags from "../../Common/EditTags"
import TradeModal from "../TradeModal"
import BuyRefundModal from "./BuyRefundModal"
import EditBuyOrderModal from "./EditBuyOrderModal"
import XmlReport from "../XmlReport"

const BuyOrder = props => {
  const [orderId] = useState(Utils.getLastUrlSegment(props.location))
  const [tradeModal, setTradeModal] = useState(false)
  const [refundModal, setRefundModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [reportXmlModal, setReportXmlModal] = useState(false)
  const [fastXmlExport, setFastXmlExport] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [order, setOrder] = useState(null)
  const [editTagsModal, setEditTagsModal] = useState(false)
  const [recurringPaymentData, setRecurringPaymentData] = useState(false)

  const autoRefundChannels = [51, 52]

  const getOrder = useCallback(() => {
    setIsLoading(true)
    BackOfficeApi.endpoints.buyOrderDetails
      .getOne({ id: orderId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        if (isRecurringOrder(data.channel)) {
          getRecurringPayment(data.orderId)
        }
        setIsLoading(false)
        setOrder(data)
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        setIsLoading(false)
      })
  }, [orderId])

  useEffect(() => {
    getOrder()
  }, [])

  const toggleModal = useCallback(modalName => {
    switch (modalName) {
      case "tradeModal":
        setTradeModal(prev => !prev)
        break
      case "refundModal":
        setRefundModal(prev => !prev)
        break
      case "editModal":
        setEditModal(prev => !prev)
        break
      case "reportXmlModal":
        setReportXmlModal(prev => !prev)
        break
      case "editTagsModal":
        setEditTagsModal(prev => !prev)
        break
      case "fastXmlExport":
        setFastXmlExport(prev => !prev)
        break
      default:
        break
    }
  }, [])

  const getRecurringPayment = orderId => {
    setIsLoading(true)
    BackOfficeApi.endpoints.getRecurringPaymentByOrderId
      .getOne({ orderId: orderId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        console.log(data)
        setRecurringPaymentData(data)
        setIsLoading(false)
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        setIsLoading(false)
      })
  }

  const onActionPerformed = useCallback(
    action => {
      switch (action) {
        case "order_trade":
          toggleModal("tradeModal")
          break
        case "order_send_crypto":
          sendCrypto()
          break
        case "order_freeze":
          freezeOrder()
          break
        case "order_unfreeze":
          unfreezeOrder()
          break
        case "order_refund":
          toggleModal("refundModal")
          break
        case "order_delete":
          deleteOrder()
          break
        case "order_undelete":
          undeleteOrder()
          break
        case "order_edit":
          toggleModal("editModal")
          break
        case "report_xml":
          setFastXmlExport(false)
          toggleModal("reportXmlModal")
          break
        case "fast_xml_export":
          toggleModal("fastXmlExport")
          break
        case "banlist_address":
          banlistAddress()
          break
        default:
          break
      }
    },
    [toggleModal],
  )

  const tradeOrder = provider => {
    toggleModal("tradeModal")

    let formData = new FormData()
    formData.append("orderId", orderId)
    formData.append("provider", provider)

    BackOfficeApi.endpoints.tradeBuyOrder
      .create(formData)
      .then(response => {
        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
          getOrder()
          return
        }
        throw Error()
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
        setIsLoading(false)
      })
  }

  const sendCrypto = () => {
    createAction(
      BackOfficeApi.endpoints.sendToPaymentGateway,
      {},
      { orderId: order.orderId },
    )
  }

  const freezeOrder = () => {
    createAction(BackOfficeApi.endpoints.freezeBuyOrder, {}, { id: orderId })
  }

  const unfreezeOrder = () => {
    createAction(BackOfficeApi.endpoints.unfreezeBuyOrder, {}, { id: orderId })
  }

  const refundOrder = (reason, date, adminFee) => {
    let formData = new FormData()
    formData.append("reason", reason)
    formData.append("refundDate", date)
    formData.append("adminFee", adminFee)

    BackOfficeApi.endpoints.refundBuyOrder
      .create(formData, { id: orderId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error()
      })
      .then(json => {
        if (autoRefundChannels.includes(order.channel)) {
          Utils.showSweetAlertOk("This payment is automatically refunded").then(
            () => {
              getOrder()
            },
          )
        } else {
          let refundReference = json.refundReference
          Utils.showSweetAlertOk(
            "Refund reference: " + refundReference,
            "Use the reference above when refunding in bank, if the refund is through the bank",
          ).then(() => {
            getOrder()
          })
        }
      })
      .catch(() => {
        ToastUtils.toastSaveError2()
        setIsLoading(false)
      })
  }

  const deleteOrder = () => {
    let formData = new FormData()
    formData.append("userId", StorageUtils.getUserId())
    formData.append("issellorder", "false")
    formData.append("orderid", orderId)
    formData.append("lazydelete", "true")
    createAction(BackOfficeApi.endpoints.deleteOrder, formData)
  }

  const undeleteOrder = () => {
    let formData = new FormData()
    formData.append("userId", StorageUtils.getUserId())
    formData.append("issellorder", "false")
    formData.append("orderid", orderId)
    formData.append("lazydelete", "false")
    createAction(BackOfficeApi.endpoints.deleteOrder, formData)
  }

  const banlistAddress = () => {
    let formData = new FormData()
    formData.append("ids", order.orderMapId)
    createAction(BackOfficeApi.endpoints.banlistOrdersAddress, formData)
  }

  const createAction = (endpoint, formData, ids) => {
    endpoint
      .create(formData, ids)
      .then(response => {
        if (!response.ok) {
          throw new Error()
        } else {
          ToastUtils.toastExecuteSuccess2()
        }
      })
      .then(() => {
        getOrder()
      })
      .catch(() => {
        ToastUtils.toastSaveError2()
        setIsLoading(false)
      })
  }

  const onEditSave = () => {
    ToastUtils.toastSaveSuccess2()
    setEditModal(false)
    getOrder()
  }

  const editTagsOnChange = () => {
    setEditTagsModal(false)
    getOrder()
  }

  const isRecurringOrder = channelId => {
    return channelId === 131
  }

  return (
    <ContentWrapper>
      <Row>
        <Col lg={8}>
          <BuyOrderInfoArea
            order={order}
            isLoading={isLoading}
            onEditTagClick={() => toggleModal("editTagsModal")}
            recurringPayment={recurringPaymentData}
          />
        </Col>
        <Col lg={4}>
          <Col className="pl-0 pr-0">
            <BuyOrderActionsArea
              order={order}
              isLoading={isLoading}
              onActionPerformed={onActionPerformed}
            />
          </Col>
          <Col className="pl-0 pr-0">
            {order && <CommentArea id={order.orderMapId} type="ORDER" />}
          </Col>
        </Col>
      </Row>
      <EditTags
        order={order}
        open={editTagsModal}
        toggle={() => toggleModal("editTagsModal")}
        onChange={editTagsOnChange}
        type="ORDER"
      />
      <BuyRefundModal
        open={refundModal}
        toggle={() => toggleModal("refundModal")}
        onRefund={refundOrder}
      />
      <TradeModal
        order={order}
        open={tradeModal}
        onToggle={() => toggleModal("tradeModal")}
        onTrade={tradeOrder}
      />
      <EditBuyOrderModal
        order={order}
        open={editModal}
        onToggle={() => toggleModal("editModal")}
        onSave={onEditSave}
      />
      {order && (
        <XmlReport
          orderOrTransactionIds={order.orderMapId}
          customerId={order.customerId}
          open={reportXmlModal}
          onToggle={() => toggleModal("reportXmlModal")}
          fastXmlExport={fastXmlExport}
        />
      )}
    </ContentWrapper>
  )
}

export default BuyOrder
