import React from "react"
import { Row, Col, CardBody } from "reactstrap"

const BuyOrderRecurringDetailsSection = ({ recurringPayment }) => {
  if (!recurringPayment) return null
  return (
    <div>
      <CardBody className="pt-0">
        <Row className="no-gutters">
          <Col lg={12}>
            <p
              className="card-section-header"
              style={{ padding: "0.75rem 1.25rem 0.75rem 0" }}
            >
              Recurring Payment Details
            </p>
            <Col lg={12}>
              <table className="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td>Recurring Payment ID</td>
                    <td>{recurringPayment.id}</td>
                  </tr>
                  <tr>
                    <td>Frequency</td>
                    <td>{recurringPayment.frequency}</td>
                  </tr>
                  <tr>
                    <td>Day of Execution</td>
                    <td>{recurringPayment.dayOfExecution}</td>
                  </tr>
                  <tr>
                    <td>Card Id</td>
                    <td>{recurringPayment.card?.id}</td>
                  </tr>
                  <tr>
                    <td>Card Obfuscated PAN</td>
                    <td>{recurringPayment.card?.obfuscatedPAN}</td>
                  </tr>
                  <tr>
                    <td>Card Holder Name</td>
                    <td>{recurringPayment.card?.holderName}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Col>
        </Row>
      </CardBody>
    </div>
  )
}

export default BuyOrderRecurringDetailsSection
